body {
  font-family: "TeleGroteskNext-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #262626;
  line-height: 1.5rem;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.cancel-link{
  margin-left: 168px;
  margin-top: -69px;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  color: black;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.error-size {
  font-size: 1rem;
  width: 100%;
  margin-top: .25rem;
  color: #aa1e4b;
}

.continue-frontdoor{
  margin-left: 14px;
}

.cancel-frontdoor{
  margin-left: 14px;
}

.btn-size {
  max-width: 20px;
}

.max-width-frondoor{
  max-width: 589px;
}

.frontdoor-label-size{
  font-size: 1rem;
}

.frontdoor {
  min-height: 91vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  margin-top: 30px;
  margin-left: 11px;
  margin-right: 12px;
}

.App-header {
  min-height: 100vh;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}
.offerSection {
  margin-left: 0px;
  margin-bottom: 1rem;
  
  a {
    font-size: 1rem;
  }
}

.tex-magintop {
  margin-top: 35px;
}

.white {
  color: #fff;
}

.magenta {
  color: #e20074;
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip .tooltip-text {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 500px;
  left: 20px;
  color: white;
  background: black;
  padding: 10px;
  font-size: 18px;
  display: none;
}

.custom-tooltip i:hover + .tooltip-text {
  display: block;
}

.purple {
  color: #ea04ae;
}

.bg2 {
  background-image: url("/images/default_thanks_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}


.hero1 {
  color: #fff;
  text-align: center;
  padding-top: 230px;
  padding-bottom: 30px;
}

.hero2,
.hero2-alt {
  color: #fff;
  padding-top: 150px;
  padding-bottom: 30px;
  text-align: center;
}

.hero2-alt {
  padding-top: 30px;
}

.hero3 {
  padding-top: 20px;
  text-align: center;
}

.hero5 {
  color: #fff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

.hero-philo {
  color: #fff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

.full {
  min-height: 720px;
}

.two-thirds {
  min-height: 480px;
}

.half {
  min-height: 360px;
}

.quarter {
  min-height: 180px;
}

.magenta-strip {
  text-align: center !important;
  background-color: #e20074;
  color: #fff;
}

.teleicon {
  font-family: "TeleIcon-Outline";
  /*    color: #e20074;*/
  font-size: 1rem;
  margin-left: 5px;
  text-decoration: none !important;
}

.legal {
  font-family: "Arial";
  font-size: 12pt;
  color: #6a6a6a;
}

.faq-card-body {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: left;
}

.p-r3dstapler-d {
  max-width: 356px;
}

.r3dstapler-thankyou-container {
  padding-left: 6vh;
  color: white;
}

.r3dstapler-error-text {
  padding-right: 12px;
  padding-left: 1vh;
}

.p-thank-r3dstpaler {
  padding-left: 130px;
}

.faq-card-text {
  padding-left: 2rem;
  text-align: left;
}

.no-padding {
  padding: 0px;
}

.div-pad-bottom {
  padding-bottom: 2%;
}

.reward-btn {
  color: #fff !important;
  text-decoration: none !important;
  background-color: #e20074;
  font-size: 1rem;
  padding: 8px 20px 8px 20px;
  min-width: 120px;
  width: 250px;
  margin-bottom: 20px;
}

.reward-btn:hover {
  background-color: #bb0061;
}

h1 {
  font-size: 3.2rem;
  letter-spacing: -2px;
  line-height: 3.2rem;
}

h3 {
  font-size: 1.3rem;
  text-align: center;
}

h1 {
  font-family: "TeleNeoWeb-ExtraBold", sans-serif;
  font-weight: 800;
}

h2,
h3 {
  font-family: "TeleGroteskNext-Ultra", sans-serif;
  font-weight: 800;
}

p {
  font-size: 1rem;
}

ul,
ol {
  text-align: left;
  font-size: 1rem;
}

a:link,
.dropdown-link {
  color: #e20074;
  font-weight: 600;
  text-decoration: underline;
}

a:hover,
a:focus,
a:visited,
a:active {
  color: #e20074;
  text-decoration: underline;
}

/* COMMON ELEMENTS */

.form-control {
  height: 45px;
  min-width: 120px;
  padding-left: 20px;
  border-radius: 0px;
  border: none;
  font-size: 1rem;
  background-color: #f5f5f5;
}

.nav-link {
  padding: 0.5rem 1rem 0.5rem 0;
}

.nav-link:hover {
  text-decoration: underline;
}

/* COMMON ELEMENTS */

.pointer {
  cursor: pointer;
}

.text-magenta {
  color: #e20074;
}

.ineligible-error {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 1rem;
}

.title-paramount {
  font-family: "TeleNeoWeb-ExtraBold", sans-serif;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4rem;
}

.title-amazon {
  font-family: "TeleNeoWeb-ExtraBold", sans-serif;
  font-weight: 800;
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 260px;
}

/* BUTTONS */

.btn {
  font-size: 1rem;
  padding: 8px 20px 8px 20px;
  min-width: 120px;
  border-radius: 0px;
  width: 250px;
  margin-bottom: 20px;
}

.btn-primary1,
.btn-primary1:hover,
.btn-primary1:focus,
.btn-primary1:active,
.btn-primary1:visited {
  color: #fff;
  border: none !important;
  border-radius: 0px;
  background-color: #e20074;
}

.btn-primary2,
.btn-primary2:hover,
.btn-primary2:focus,
.btn-primary2:active,
.btn-primary2:visited {
  color: #e20074;
  background-color: #fff;
  border: none !important;
  border-radius: 0px;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:visited {
  color: #e20074;
  background-color: transparent;
  border: 1px solid #e20074;
  border-radius: 0px;
}

/* BUTTONS */

/* Footer */

footer {
  background-color: #999;
}

footer p {
  font-size: 0.75rem;
  line-height: 140%;
}

footer a:link {
  font-size: 0.75rem;
  font-weight: bold;
  font-weight: bold;
  text-decoration: none;
}

/* Footer */

/* Special Over-writes */
.react-datepicker-wrapper {
  display: block !important;
}

.sheerId {
  width: 500px;
  height: 700px;
  border: none;
}

.sirius-sorry-details {
  width: 35%;
}

.sirius-sorry {
  margin-top: 5px;
}

/* Special over-writes */

/* Media Queries */


@media (min-width: 576px) {


  .bg2 {
    background-image: url("/images/default_thanks_desktop.jpg");
  }

}

@media (min-width: 768px) {
  .sirius-sorry {
    margin-top: 5px;
  }
  
  .frontdoor {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    text-align: left;
    margin-top: 23px;
  }

  p.upload-font {
    font-size: 28px;
  }

  .hero1 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .r3dstapler-container {
    height: 96vh;
    padding-left: 22vh;
  }

  .r3dstapler-thankyou-container {
    padding-top: 55px;
    color: white;
  }

  .r3dstapler-error-text {
    max-width: 122vh;
  }

  .p-r3dstapler-d {
    max-width: 788px;
  }

  .hero2,
  .hero2-alt {
    padding-bottom: 150px;
    text-align: left;
  }

  .hero2-alt {
    padding-top: 150px;
  }

  .hero3 {
    text-align: left;
  }

  .hero-philo {
    color: #fff;
    text-align: center;
    padding-top: 20rem;
    padding-bottom: 30px;
  }

  .btn {
    width: auto;
    margin-bottom: 30px;
  }

  h1 {
    font-weight: 800;
    font-size: 4rem;
    letter-spacing: -2px;
    line-height: 4rem;
  }

  h3 {
    font-size: 1.75rem;
    text-align: left;
  }

  p {
    font-size: 1rem;
  }

  ul {
    font-size: 1rem;
  }

  .btn-paramount {
    min-width: 600px !important;
    text-align: center !important;
  }

  .btn-upload {
    text-align: center !important;
    padding-bottom: 2%;
  }
}

@media screen and (max-width: 600px) {

  .sirius-sorry {
    margin-top: 210px;
  }

  .sirius-sorry-details {
    width: 100%;
  }


  #tracker table {
    border: 0;
  }

  #tracker table thead {
    display: none;
  }

  #tracker table tr {
    margin-bottom: 20px;
    display: block;
    border: 2px solid lightgrey;
    box-shadow: 2px 2px 1px lightgray;
  }

  #tracker table td {
    display: block;
    text-align: right;
    font-size: 0.75rem;
  }

  #tracker table td:last-child {
    border-bottom: 0;
  }

  #tracker table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .title-paramount {
    font-family: "TeleNeoWeb-ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 38px;
    line-height: 40px;
    margin-top: 170px;
  }


  .title-amazon {
    font-family: "TeleNeoWeb-ExtraBold", sans-serif;
    font-weight: 800;
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 282px;
  }


  .p-paramount {
    text-align: left !important;
  }

  .p-r3dstapler {
    font-size: 44px;
  }

}

.youtube-subtitle {
  font-size: 12px;
}

.youtube-subtitle-italic {
  font-family: Arial;
  font-style: italic;
}

.accessibility-font {
  font-size: 16px;
}

.drag-and-drop-color {
  color: #716f6f
}

/* Checkbox magenta styling */

.checkbox-wrapper {
  border-bottom: 1px solid #e20074;
  margin-bottom: 20px;
}

.checkbox-padding {
  text-align: center;
  margin-right: 1.5%;
  margin-left: 1%;
}

.checkbox-inline {
  display: inline-block;
}

.checkbox-wrapper:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.checkbox-wrapper label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #e20074;
  outline: none;
  cursor: pointer;
}

input.checked {
  background-color: #FFFFFF;
  position: relative;
}

input.checked::before {
  content: "\2714";
  font-size: 1.9em;
  color: #e20074;
  position: absolute;
  right: -3px;
  top: -4px;
}

.link-for-landing-page {
  text-decoration: underline;
  cursor: pointer;
  color: #e20074;
  font-weight: 600
}



@media only screen 
and (device-width : 1024px) 
and (device-height: 600px) {
  .r3dstapler-thankyou-container {
    padding-top: 41px;
    height: 118vh;
}

  .r3dstapler-error-text {
    max-width: 95vh;
}
.p-r3dstapler-d {
  max-width: 564px;
}

.r3dstapler-container {
  height: 114vh;
  padding-left: 5vh;
  padding-top: 1vh;
}

}

@media only screen 
and (device-width : 1024px) 
and (device-height: 1366px) {
.r3dstapler-container {
  height: 80vh;
  padding-top: 34vh;
  padding-left: 4vh;
}
.r3dstapler-thankyou-container {
  color: white;
  padding-top: 33vh;
}

}

@media only screen 
and (device-width : 1280px) 
and (device-height: 800px) {
  .r3dstapler-container {
    height: 75vh;
    padding-left: 5vh;
  }
  .r3dstapler-thankyou-container {
    padding-top: 770px;
    color: white;
  }
  .p-r3dstapler-d {
    max-width: 675px;
 }
 .r3dstapler-error-text {
  max-width: 85vh;
 }

}

@media only screen 
and (device-width : 1280px) 
and (device-height: 800px) {
  .r3dstapler-container {
    height: 75vh;
    padding-left: 5vh;
  }
  .p-r3dstapler-d {
    max-width: 788px;
  }
  .r3dstapler-thankyou-container {
    padding-top: 54px;
    color: #fff;
  }

}

@media only screen 
and (device-width : 768px) 
and (device-height: 1024px) {
  .r3dstapler-container {
    height: 62vh;
    padding-left: 2vh;
  }
  .r3dstapler-thankyou-container {
    padding-top: 770px;
    color: white;
  }
}

  @media only screen 
and (device-width : 820px) 
and (device-height: 1180px) {
  .r3dstapler-container {
    height: 87vh;
    padding-top: 37vh;
    padding-left: 1vh;
  }
  .r3dstapler-thankyou-container {
    padding-top: 1vh;
    padding-top: 33vh;
  }
}

@media only screen 
and (device-width : 912px) 
and (device-height: 1368px) {
  .r3dstapler-container {
    height: 109vh;
    padding-top: 62vh;
    padding-left: 1vh;
  }

  .r3dstapler-thankyou-container {
    padding-top: 55px;
    color: #fff;
}
}


@media only screen 
and (device-width : 540px) 
and (device-height: 720px) {
  .r3dstapler-container {
    padding-left: 2vh;
  }
  .r3dstapler-thankyou-container {
    padding-top: 77vh;
    color: white;
  }
}

@media only screen 
and (device-width : 430px) 
and (device-height: 932px) {

.r3dstapler-thankyou-container {
  color: white;
  padding-top: 47vh;
  
}

}
@media only screen 
and (device-width : 280px) 
and (device-height: 653px) {

  .r3dstapler-thankyou-container {
    padding-top: 299px;
    color: #fff;
}

}

@media only screen 
and (device-width : 412px) 
and (device-height: 915px) {

  . {
    padding-top: 3vh;
}

}